export default {
  name: 'task-instructions',
  methods: {
    getExampleImage() {
      return require('@/assets/example.png')
    },
    loadNextPage() {
      this.$router.push('/practice-instructions')
    }
  }
}